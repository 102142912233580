import { ALL_USERS } from './constants';
import { AxiosHelper } from './ms-utils';

const BROADCAST = {
  LIST_ALL: '',
  BY_ID: id => `/id/${id}`,
  LIST_BY_CHILD_ID: childId => `/child/${childId}`,
  RECALL: id => `/recall/${id}`,
  SAVE: `/save`,
  MARK_READ: id => `/read/${id}`,
  DELETE: id => `/id/${id}`,
  UPLOAD_ATTACHMENT: schoolId =>
    `/attachment/get-signed-upload-url/${schoolId}`,
  GET_SIGNED_PRIVATE_URL: '/attachment/get-file-signed-access-url',
  GET_MS_CENTRE: '/get-accessible-centres',
  GET_MS_LEVEL: schoolId => `/level/school/${schoolId}`,
};

const NOTIFICATION = {
  LIST: '',
  MARK_READ: '/mark-as-read',
};

export const HEALTH_ATTENDANCE_ENDPOINT = {
  GET_CHECK_IN_OUT_TOKEN: '/attendance/check-in-out-token',
  GET_CENTRE_ATTENDANCE_SUMMARIES: '/attendance/centre-attendance-summaries',
  GET_CENTRE_ATTENDANCE_SUMMARIES_FOR_STAFF:
    '/attendance/centre-attendance-summaries-for-staff',
};

export const BROADCAST_ENDPOINT = BROADCAST;
export const NOTIFICATION_ENDPOINT = NOTIFICATION;
export const CENTRE_SVC_ENDPOINT = {
  GET_CENTRES_BY_SCHOOL_IDS: '/get-centres-by-school-ids',
};

export const TERM_CENTRES_ENDPOINT = {
  TERM_CENTRES: '/get-term-centres',
};

export const REPORT_SVC_ENDPOINT = {
  GET_REPORTS: '/',
  GET_DOCUMENTS: '/documents',
  GENERATE_REPORT: '/generate',
};

export const MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT = {
  PORTFOLIO: {
    DELETE: id => `/portfolio/${id}`,
    SAVE_CHILD_PORTFOLIO: '/portfolio/save-child-portfolio',
    UPDATE_PORTFOLIO_STATUS: '/portfolio/update-child-portfolio-status',
    GET_PORTFOLIO_PHOTO_UPLOAD_URL: '/portfolio/get-portfolio-photo-upload-url',
    GET_CHILD_AREA_OF_INTEREST_AND_RECOMMENDATION:
      '/portfolio/get-child-area-of-interest-and-recommendation',
    GET_MY_DOWNLOAD_PORTFOLIO: '/portfolio/get-my-download-portfolio',
    GET_PORTFOLIO_TERM_BY_YEAR: '/portfolio/get-term-by-year',
    GET_CHILD_PORTFOLIO: '/portfolio/get-child-portfolio',
    GET_PORTFOLIO_BY_ID: id => `/portfolio/${id}`,
    GET_PORTFOLIO_CLASS_TEACHERS: (portfolioIds = [], params = {}) => [
      `/portfolio/get-portfolio-class-teachers-by-portfolio-ids?${new URLSearchParams(
        {
          page: 1,
          ...params,
        }
      )}`,
      {
        portfolio_ids: portfolioIds,
      },
    ],
    GET_TERM_BY_IDS: (termIds = [], params = {}) => [
      `/portfolio/get-terms-by-ids?${new URLSearchParams({
        page: 1,
        perPage: termIds.length,
        ...params,
      })}`,
      {
        term_ids: termIds,
      },
    ],
    GET_PORTFOLIO_CHILD_LEARNING_GOAL_EVALUATIONS:
      '/portfolio/get-portfolio-child-learning-goal-evaluations',
    GET_PORTFOLIO_CLASS_ACTIVITIES: '/portfolio/get-portfolio-class-activities',
    GET_PORTFOLIO_CLASS_ACTIVITY_BY_ID: portfolioId =>
      `/portfolio/portfolio-class-activity/${portfolioId}`,
    GET_DOMAINS_BY_DOMAIN_IDS: (domainIds = [], params = {}) => [
      `/portfolio/get-domain-by-domain-ids?${new URLSearchParams({
        page: 1,
        perPage: domainIds?.length,
        ...params,
      })}`,
      {
        domain_ids: domainIds,
      },
    ],
    BULK_PUBLISH_PORTFOLIOS: (portfolioIds = []) => [
      '/portfolio/change-bulk-portfolios-status-to-published',
      {
        portfolio_ids: portfolioIds,
      },
    ],
    GET_LIST_DOMAIN: '/domain/get-list-domain',
    GET_PORTFOLIO_UPLOAD_URL: '/portfolio/get-portfolio-pdf-upload-url',
    SAVE_CHILD_EVALUATION:
      '/portfolio/insert-or-update-child-learning-goal-evaluation',
  },
  CHECKLIST: {
    SAVE_SEMESTER_CHECKLIST_EVALUATION: '/checklist/save-semester-evaluation',
    SAVE_MULTI_LITERACY_CHECKLIST_EVALUATION:
      '/checklist/save-multi-literacy-evaluation',
    SAVE_LANGUAGE_FUNCTION_CHECKLIST_EVALUATION:
      '/checklist/save-language-function-evaluation',
    SAVE_LITERACY_TS1_CHECKLIST_EVALUATION:
      '/checklist/save-child-literacy-transaction-one-evaluation',
    SAVE_LITERACY_TS2_CHECKLIST_EVALUATION:
      '/checklist/save-child-literacy-transaction-two-evaluation',
    SAVE_READING_BEHAVIOR_CHECKLIST_EVALUATION:
      '/checklist/save-reading-behavior-evaluation',
    SAVE_HIGH_FREQUENCY_CHECKLIST_EVALUATION:
      '/checklist/save-high-frequency-evaluation',
    SAVE_LETTER_IDENTIFICATION_CHECKLIST_EVALUATION:
      '/checklist/save-letter-identification-evaluation',
    GET_SEMESTER_CHECKLIST: '/checklist/get-semester-checklist',
    GET_HIGH_FREQUENCY_CHECKLIST: '/checklist/get-high-frequency-checklist',
    GET_CHILD_CHECKLIST: '/checklist/get-child-checklist',
    GET_LIST_DOMAINS_BY_LEVEL: '/domain/get-list-domain-by-level',
    GET_EVALUATION_LABELS: levelId =>
      `/checklist/get-evaluation-labels/${levelId}`,
    GET_LITERACY_TRACKING_SECTION_ONE:
      '/checklist/get-child-literacy-tracking-section-one',
    GET_LITERACY_TRACKING_SECTION_TWO:
      '/checklist/get-child-literacy-tracking-section-two',
    GET_CHILD_LITERACY_TRACKING_SECTION_TWO_OPTIONS: (
      fkLiteracyTrackingSectionTwos = [],
      params = {}
    ) => [
      `/checklist/get-child-literacy-tracking-section-two-input-options?${new URLSearchParams(
        params
      )}`,
      { literacy_tracking_section_two_ids: fkLiteracyTrackingSectionTwos },
    ],
    GET_LETTER_IDENTIFICATION: '/checklist/get-letter-identification-checklist',
    GET_READING_BEHAVIOR_CHECKLIST: '/checklist/get-reading-behavior-checklist',
    GET_CHILD_SEMESTER_EVALUATION: '/checklist/get-child-semester-evaluation',
    GET_MIGRATED_CHECKLIST_CLASS:
      '/checklist/get-migrated-checklist-class-by-centre',
  },
  CLASS_ACTIVITY: {
    REJECT_MS_CLASS_ACTIVITY: id =>
      `/class-activity/reject-class-activity/${id}`,
    GET_MY_DOWNLOAD_ALBUM: '/class-activity/get-my-download-album',
    FIND_CLASS_ACTIVITY: '/class-activity/find-class-activities',
    GET_CLASS_ACTIVITY_IMAGES: (classActivityIds = [], params = {}) => [
      `/class-activity/get-class-activity-images-by-class-activity-ids?${new URLSearchParams(
        {
          ...params,
        }
      )}`,
      {
        class_activity_ids: classActivityIds,
      },
    ],
    BULK_PUBLISH_RECORD_OF_LEARNING_AND_DEVELOPMENT:
      '/class-activity/bulk-publish-record-of-learning-and-development',
    SEARCH_CLASS_ACTIVITY_TAG: '/class-activity/search-class-activity-tag',
    DELETE_CLASS_ACTIVITYIES: '/class-activity/delete-class-activities',
    CREATE_OR_UPDATE_CLASS_ACTIVITY: '/class-activity',
    PUBLISH_CLASS_ACTIVITY: id =>
      `/class-activity/publish-class-activity/${id}`,
    UPDATE_CLASS_ACTIVITY_STATUS:
      '/class-activity/update-class-activity-status',
    CLASS_ACTIVITY_MEDIA: '/class-activity/class-activity-media',
    GET_CLASS_ACTIVITY_TAGS:
      '/class-activity/class-activity-tag-relation/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_LOGS: '/class-activity/logs/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_PARENT_INTERACTIONS:
      '/class-activity/parent-interactions/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_LEARNING_GOALS:
      '/class-activity/learning-goal/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_CLASSES:
      '/class-activity/class/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_CHILDRENS:
      '/class-activity/child/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_DEVELOPMENT_LEARNING_AREAS:
      '/class-activity/development-learning-areas/get-by-class-activity-ids',
    GET_CLASS_ACTIVITY_LESSON_PLANS:
      '/class-activity/lesson-plan/get-by-class-activity-ids',
    FIND_OBSERVATION_DASHBOARD_V2:
      '/class-activity/find-observation-dashboard-v2',
    FIND_OBSERVATION_DASHBOARD_MIGRATED_V2:
      '/class-activity/find-observation-dashboard-migrated-v2',
    CLASS_ACTIVITY_IMAGE_MIGRATED: (classActivityIds = [], params = {}) => [
      `/class-activity/class-activity-image-migrated/get-by-image-migrated-ids?${new URLSearchParams(
        {
          ...params,
        }
      )}`,
      {
        class_activity_migrated_ids: classActivityIds,
      },
    ],
    GET_CLASS_ACTIVITY_USER_STAFF: (classActivityIds = [], params = {}) => [
      `/class-activity/get-user-staff-by-class-activity-ids?${new URLSearchParams(
        {
          page: 1,
          perPage: classActivityIds.length,
          ...params,
        }
      )}`,
      {
        class_activity_ids: classActivityIds,
      },
    ],
    GET_LESSON_PLANS: '/class-activity/lesson-plan/get-lesson-plan',
    GET_LESSON_PLAN_BY_ID: '/lesson-plan-learning-goal/get-by-lesson-plan-ids',
    CREATE_CLASS_ACTIVITY_TAG: '/class-activity/class-activity-tag',
  },
  USER: {
    GET_BY_IDS: (userIds = []) => [
      `/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: userIds.length,
        status: ALL_USERS,
      })}`,
      {
        userIds,
      },
    ],
    // staff
    GET_STAFF_USER_BY_USER_IDS: userIds => [
      `staff/staff-user-by-user-ids?${new URLSearchParams({
        page: 1,
        perPage: userIds.length,
      })}`,
      { user_ids: userIds },
    ],
  },
  CLASS: {
    GET_CLASS_BY_IDS: (classIds = []) => [
      `/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: classIds.length,
      })}`,
      {
        classIds,
      },
    ],
    GET_CLASS_TEACHERS_BY_CLASS_IDS: (classIds = [], params = {}) => [
      `/class-teacher/get-by-class-ids?${new URLSearchParams({
        page: 1,
        ...params,
      })}`,
      { class_ids: classIds },
    ],
    GET_LEARNING_GOALS_BY_CLASS: '/learning-goal/get-learning-goals-by-class',
    GET_LEARNING_GOALS_BY_IDS: (ids = [], params = {}) => [
      `/class-activity/learning-goal/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: ids?.length,
        ...params,
      })}`,
      {
        learning_goal_ids: ids,
      },
    ],
    GET_LEARNING_GOALS_BY_DOMAIN_IDS: (ids = [], params = {}) => [
      `/learning-goal/get-by-domain-ids?${new URLSearchParams({
        page: 1,
        perPage: ids?.length,
        ...params,
      })}`,
      {
        domain_ids: ids,
      },
    ],
    GET_BY_CENTRE_IDS: (centreIds = [], params = {}) => [
      `/get-by-centres?${new URLSearchParams({
        page: 1,
        perPage: centreIds?.length,
        ...params,
      })}`,
      {
        centreIds,
      },
    ],
  },
  SCHOOL: {
    GET_LEVEL_BY_IDS: (levelIds = []) => [
      `/level/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: levelIds.length,
      })}`,
      { level_ids: levelIds },
    ],
  },
  CHILD: {
    GET_CHILD_BY_IDS: (childIds = []) => [
      `/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: childIds.length,
      })}`,
      { childIds },
    ],
    SEARCH_CHILD_NOT_ASSINED_TO_BUS: (query, centreId) =>
      `/search/child-not-assigned-to-bus?${new URLSearchParams({
        q: query,
        centreId,
      })}`,
    GET_CHILD_CURRENT_CLASS_BY_IDS: (childIds = []) => [
      `/child-class/get-child-current-class-by-child-ids?${new URLSearchParams({
        page: 1,
        perPage: childIds.length,
      })}`,
      { child_ids: childIds },
    ],
    GET_LEVEL_BY_CHILD_IDS: (childIds = []) => [
      '/child-level/get-level-by-child-ids',
      {
        childIds,
      },
    ],
    GET_CLOSED_LEVEL_BY_CHILD_IDS: (childIds = [], params = {}) => [
      `/child-level/get-closest-level-by-child-ids?${new URLSearchParams({
        page: 1,
        ...params,
      })}`,
      {
        child_ids: childIds,
      },
    ],
    GET_CHILD_CLASSES_BY_CHILD_IDS: (
      childIds = [],
      params = {},
      filter = {}
    ) => [
      `/child-class/get-all-by-child-ids?${new URLSearchParams({
        page: 1,
        ...params,
      })}`,
      {
        child_ids: childIds,
        child_class_filter: filter,
      },
    ],
    GET_CHILD_CLASSES_AT_BY_CHILD_IDS: (childIds = [], at = '') => [
      `/child-class/get-child-class-at-by-child-ids?${new URLSearchParams({
        page: 1,
      })}`,
      {
        child_ids: childIds,
        at,
      },
    ],
  },
  CENTRE: {
    GET_CENTRE_BY_IDS: (centreIds = []) => [
      `/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: centreIds.length,
      })}`,
      { centreIds },
    ],
    GET_PROGRAMS_BY_IDS: (programIds = [], params = {}) => [
      `/program/get-by-ids?${new URLSearchParams({
        page: 1,
        perPage: programIds?.length,
        ...params,
      })}`,
      { program_ids: programIds },
    ],
    GET_CHILD_HISTORICAL_CENTRE: '/get-child-historical-centre',
    GET_CHILD_HISTORICAL_CENTRE_CLASS: (childId, centreId) => [
      `/get-child-historical-centre-class`,
      {
        child_id: childId,
        ...(centreId && { centre_id: centreId }),
      },
    ],
  },
};

export const MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS = {
  EVENT: {
    GET_EVENTS: '/events/',
    GET_EVENT_TYPES: '/events/types',
    GET_EVENT_BY_ID: eventId => `/events/${eventId}`,
    CREATE_EVENT: '/events',
    GET_EVENT_PHOTO_ALBLUM_UPLOAD_URL: eventId =>
      `/events/${eventId}/photo-upload-url`,
    GET_EVENT_UPLOAD_URL_FOR_SCHOOL: 'events/image-upload-url',
    CANCEL_EVENT: eventId => `events/${eventId}/cancel`,
    CREATE_DRAFT_EVENT: '/draft-events',
    GET_DRAFT_EVENTS: '/draft-events',
    SUBMIT_DRAFT_EVENT: eventId => `/draft-events/${eventId}/submit`,
    GET_DRAFT_EVENT_BY_ID: eventId => `/draft-events/${eventId}`,
    UPDATE_EVENT: eventId => `/events/${eventId}`,
    UPDATE_DRAFT_EVENT: eventId => `/draft-events/${eventId}`,
    DELETE_DRAFT_EVENT: eventId => `/draft-events/${eventId}`,
    PUBLISH_EVENT: eventId => `/events/${eventId}/publish`,
    // rsvp
    GET_EVENT_RSVPS: eventId => `/events/${eventId}/rsvps`,
    EXPORT_RSVP_LIST: eventId => `/events/${eventId}/export`,
    GET_RSVP_COUNT_DETAILS_BY_IDS: 'events/rsvp-count-details',
    REMOVE_CHILD_FROM_RSVP_LIST: (eventId, rsvpId) =>
      `/events/${eventId}/rsvps/${rsvpId}`,
    REMOVE_RSVP_GUESTS: (eventId, rsvpId, guestIds = []) =>
      `/events/${eventId}/rsvps/${rsvpId}/guests?${new URLSearchParams({
        guestIds: guestIds.join(','),
      })}`,
    ADD_EVENT_RSVP: eventId => `/events/${eventId}/rsvps`,
    // photos
    GET_EVENT_PHOTOS: eventId => `events/${eventId}/photos`,
    ADD_EVENT_PHOTOS: eventId => `events/${eventId}/photos`,
    UPDATE_EVENT_PHOTOS_INDEX: eventId => `events/${eventId}/photos-index`,
    DELETE_EVENT_PHOTOS: eventId => `events/${eventId}/photos`,
    // scope
    GET_EVENT_SCOPES_BY_IDS: 'events/scopes',
    // dashboard
    GET_PENDING_EVENT_GROUPED_BY_CENTER:
      '/events/pending-events-grouped-by-center',
    // calendar
    GET_CALENDAR_EVENTS: '/events/calendar',
  },
  VISITOR: {
    GET_VISITOR_LOGS: '/visitor/log',
    CODE: id => `/code/${id}`,
  },
  BUS: {
    LIST_ALL_BUS_ENTRIES: `/bus-entry/list`,
    DELETE_BUS_ENTRY: id => `/bus-entry/${id}`,
    BUS_ROUTE: `/bus-route/`,
    LIST_ALL_BUS_CHILDREN: `/bus-child/list-all-bus-children`,
    GET_BUS_BY_IDS: `bus/get-bus-by-ids`,
    CREATE_BUS_CHILD: `/bus-child/`,
    DELETE_CHID_FROM_BUS: id => `/bus-child/${id}/`,
    GET_BUS_USER_BY_IDS: `/bus-user/get-bus-user-by-ids`,
  },
  RESOURCES: {
    GET_CLASS_SECTIONS: `/class-resource/sections/`,
    CREATE_CLASS_SECTION: `/class-resource/sections/`,
    UPDATE_CLASS_SECTION: sectionId => `/class-resource/sections/${sectionId}`,
    DELETE_CLASS_SECTION: sectionId => `/class-resource/sections/${sectionId}`,
    RE_ARRANGE_CLASS_SECTIONS: `/class-resource/sections/rearrange`,
    GET_CLASS_RESOURCES: `/class-resource/resources/`,
    RE_ARRANGE_CLASS_SECTION_RESOURCES: `/class-resource/resources/rearrange`,
    CREATE_CLASS_RESOURCE: `/class-resource/resources/`,
    UPDATE_CLASS_RESOURCE: sectionId =>
      `/class-resource/resources/${sectionId}`,
    GET_CLASS_RESOURCE_UPLOAD_URL: resourceId =>
      `/class-resource/resources/${resourceId}/file-upload-url`,
    DELETE_CLASS_RESOURCE: resourceId =>
      `/class-resource/resources/${resourceId}`,
    GET_CLASS_SECTIONS_AND_RESOURCES: `/class-resource/sections-and-resources`,
  },
};

// Axios instance for notification service, will update when MS supports API Gateway
export const notificationMsInstance = AxiosHelper({
  baseURL: process.env.REACT_APP_MS_NOTIFICATION_URL,
});

export const healthAttendanceMsInstance = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_HEALTH_ATTENDANCE_URL,
  });

export const reportSerivce = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_REPORT_SVC_URL,
  });

export const centreSerivce = () =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_CENTRE_SVC_URL,
  });

export const classOpsService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_CLASS_OPS_SVC_URL });

export const userService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_USER_SVC_URL });

export const classService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_CLASS_SVC_URL });

export const schoolService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_SCHOOL_SVC_URL });

export const childService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_CHILD_SVC_URL });
