import axios from 'axios';
import { get } from 'lodash';
import { HOMEPAGE, MAINTENANCE } from 'routes/constants';
import { checkNetworkStatus } from 'check-network-status';
import {
  FEATURE_FLAGS,
  SPLIT_VALUE_OFF,
  SPLIT_VALUE_ON,
} from 'utils/constants';
import DatadogHandler from 'utils/datadog';
import { getFeatureKey, handleResponse } from '../../utils';
import LocalStore from '../../utils/localStorage';
import { handleErrorMsgInterceptor } from '../../utils/apolloUtils';

export default async config => {
  const headers = {};
  const userToken = LocalStore.getValue('userToken');
  const API_URL = process.env.REACT_APP_REACT_API_URL;

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  if (config.method === 'POST') {
    headers['content-type'] = 'application/json';
    config.data = JSON.stringify(config.data);
    config.timeout = config?.timeout || 300000;
  }

  if (!config.url) {
    config.url = API_URL;
  }

  if (!config.method) {
    config.method = 'GET';
  }

  config.headers = headers;
  try {
    const resp = await axios(config);
    if (window.location.pathname === MAINTENANCE) {
      window.location.pathname = HOMEPAGE;
    }
    return handleResponse(resp);
  } catch (ex) {
    try {
      const errorCode = get(ex, 'response.status');
      const currentPath = window.location.pathname;
      const errorMsg = get(ex, 'message');
      const requestData = get(ex, 'config.data');

      if (errorCode === 0 || errorCode === 502) {
        const networkStatus = await checkNetworkStatus();

        const checkFeatureEnabled = async () => {
          let isFeatureEnabled = await getFeatureKey(
            FEATURE_FLAGS.MAINTENANCE_PAGE_REDIRECTION
          );

          const intervalId = setInterval(async () => {
            if (isFeatureEnabled !== null && isFeatureEnabled !== undefined) {
              clearInterval(intervalId);

              if (
                isFeatureEnabled === SPLIT_VALUE_ON &&
                networkStatus &&
                currentPath !== MAINTENANCE
              ) {
                window.location.pathname = MAINTENANCE;
              } else if (
                isFeatureEnabled === SPLIT_VALUE_OFF &&
                currentPath !== HOMEPAGE
              ) {
                window.location.pathname = HOMEPAGE;
              }
            } else {
              // Retry fetching the feature flag value unitl it is either ON || OFF
              isFeatureEnabled = await getFeatureKey(
                FEATURE_FLAGS.MAINTENANCE_PAGE_REDIRECTION
              );
            }
          }, 100);
        };

        await checkFeatureEnabled();
      } else if (currentPath !== HOMEPAGE) {
        window.location.pathname = HOMEPAGE;
      } else {
        handleErrorMsgInterceptor(errorCode, errorMsg, requestData);
        return handleResponse({
          data: {
            errors: [ex.message],
          },
        });
      }
    } catch (error) {
      DatadogHandler.sendLog(error.message, {}, 'error');
    }
  }
};
