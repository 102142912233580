import { get } from 'lodash';
import {
  FETCH_EVENT_MANAGEMENT_LISTING,
  CURRENT_EVENT_CATEGORY,
  FETCH_EVENT_DETAILS,
  FETCH_GET_RSVP_COUNT,
  FETCH_RSVP_DATA,
  FETCH_CURRENT_EVENT,
  FETCH_EVENT_PHOTOS,
  FETCH_GET_EXPORT_EVENT_RSVP,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  EVENT_BY_ID,
  EXPORT_RSVP_LIST,
  GET_RSVP_STATUS_COUNT_DETAILS,
  SUBMIT_EVENT,
  GET_EVENT_PHOTO_UPLOAD_URL,
  CANCEL_EVENT,
  PUBLISH_EVENT,
  CREATE_OR_SAVE_EVENT_DRAFT,
  UPDATE_EVENT_DETAILS,
  REMOVE_CHILD_FROM_RSVP_LIST,
  REMOVE_EVENT_RSVP_GUEST,
  ADD_EVENT_PHOTOS,
  DELETE_EVENT_PHOTOS,
  UPDATE_EVENT_PHOTO_STATUS,
  GET_EVENT_PHOTO_ALBUM_UPLOAD_URL,
  GET_DRAFT_EVENTS,
  GET_PENDING_EVENTS,
  GET_UPCOMING_EVENTS,
  GET_PAST_EVENTS,
  EVENT_RSVP_LIST,
  EVENT_RSVP_COUNT,
  EVENT_LISTING_OBJECT,
  GET_ALL_EVENTS,
  DELETE_EVENT,
  ADD_CHILD_TO_RSVP_LIST,
  UPDATE_EVENT_MEDIA_INDEX,
} from '../models/eventModels';
import {
  DEFAULT_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
  FEATURE_FLAGS,
} from '../../utils/constants';
import { actionHandlerWithFeatureFlag } from '../../utils';
import {
  msFetchEventListing,
  msFetchEventByID,
  msCreateNewEvent,
  msSaveNewDraftEvent,
  msFetchCurrentEventCategory,
  msUpdateEventDetails,
  msGetEventPhotoAlbumUploadUrl,
  msCancelEvent,
  msExportRsvpList,
  msAddEventPhotos,
  msFetchEventPhotos,
  msUpdateEventMediaIndex,
  msDeleteEventDraft,
  msDeleteEventPhotos,
  msGetEventPhotoAlbumUploadUrlForSchool,
  msPublishEvent,
  msFetchRsvpCount,
  msFetchRsvpData,
  msRemoveChildFromRsvp,
  msAddChildToRsvp,
  msRemoveRsvpGuest,
} from '../../components/views/EventManagement/EventManagementHelpers';

const dispatchEventDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EVENT_DETAILS,
    key: 'eventDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEventPhotos = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EVENT_PHOTOS,
    key: 'eventPhotos',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRsvpData = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_RSVP_DATA,
    key: 'getRsvpCount',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchgetRsvpEvents = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_GET_RSVP_COUNT,
    key: 'getRsvpCount',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchExportRsvpList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_GET_EXPORT_EVENT_RSVP,
    key: 'exportRsvpList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEvents = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EVENT_MANAGEMENT_LISTING,
    key: 'allEvents',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCurrentEventCategory = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: CURRENT_EVENT_CATEGORY,
    key: 'currentEventCategory',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchfetchEventByID = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CURRENT_EVENT,
    key: 'currentEvent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const monoDeleteEventPhotos = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_EVENT_PHOTOS,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteEventPhotos = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msDeleteEventPhotos,
      monoHandler: monoDeleteEventPhotos,
      extraData: { reqData },
    }
  );
  return data;
};

export const updateEventPhotosStatus = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_EVENT_PHOTO_STATUS,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const monoGetEventPhotoAlbumUploadUrl = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_EVENT_PHOTO_ALBUM_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const getEventPhotoAlbumUploadUrl = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msGetEventPhotoAlbumUploadUrl,
      monoHandler: monoGetEventPhotoAlbumUploadUrl,
      extraData: { reqData },
    }
  );
  return data;
};

export const monoAddEventPhotos = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_EVENT_PHOTOS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const addEventPhotos = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msAddEventPhotos,
      monoHandler: monoAddEventPhotos,
      extraData: { reqData },
    }
  );
  return data;
};

export const monoFetchRsvpCount = async (dispatch, { IDEvent }) => {
  dispatchgetRsvpEvents(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_RSVP_STATUS_COUNT_DETAILS,
        variables: IDEvent,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchgetRsvpEvents(dispatch, false, data.data);
    } else {
      dispatchgetRsvpEvents(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchgetRsvpEvents(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchRsvpCount = IDEvent => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msFetchRsvpCount,
      monoHandler: monoFetchRsvpCount,
      extraData: { IDEvent, dispatchgetRsvpEvents },
    }
  );
  return data;
};

export const fetchEventDetails = reqData => async dispatch => {
  dispatchEventDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_EVENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchEventDetails(dispatch, false, data.data);
    } else {
      dispatchEventDetails(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchEventDetails(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const monoFetchEventPhotos = async (_, { reqData, dispatch }) => {
  dispatchEventPhotos(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: EVENT_BY_ID,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchEventPhotos(dispatch, false, data.data);
    } else {
      dispatchEventPhotos(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchEventPhotos(dispatch, false, null, { error: ex.message });
  }
};

const msFetchPhotos = async (_, reqDatas) => {
  const { dispatch, reqData } = reqDatas;
  try {
    const data = await msFetchEventPhotos(reqData);
    dispatchEventPhotos(dispatch, false, {
      eventById: {
        eventPhotos: data,
      },
    });
    return data;
  } catch (ex) {
    dispatchEventPhotos(dispatch, false, null, { error: ex.message });
  }
};

export const fetchEventPhotos = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msFetchPhotos,
      monoHandler: monoFetchEventPhotos,
      extraData: { reqData, dispatch },
    }
  );
  return data;
};

const msCreateEvent = async (_, reqDatas) => {
  try {
    const { dispatch, reqData } = reqDatas;
    const data = await msCreateNewEvent(dispatch, reqData);
    return data;
  } catch (ex) {
    return ex;
  }
};

export const monoCreateEvent = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SUBMIT_EVENT,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const createEvent = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msCreateEvent,
      monoHandler: monoCreateEvent,
      extraData: { reqData, dispatch },
    }
  );
  return data;
};

export const monoPublishEvent = async (_, IDEvent) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: PUBLISH_EVENT,
        variables: IDEvent,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const publishEvent = IDEvent => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msPublishEvent,
      monoHandler: monoPublishEvent,
      extraData: IDEvent,
    }
  );
  return data;
};

export const monoCancelEvent = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_EVENT,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelEvent = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msCancelEvent,
      monoHandler: monoCancelEvent,
      extraData: { reqData },
    }
  );
  return data;
};

export const monoDeleteEventDraft = async (_, { IDEvent }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_EVENT,
        variables: {
          IDEvent,
        },
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteEventDraft = IDEvent => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msDeleteEventDraft,
      monoHandler: monoDeleteEventDraft,
      extraData: { IDEvent, dispatch },
    }
  );
  return data;
};

export const monoRemoveRsvpGuest = async (_, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_EVENT_RSVP_GUEST,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const removeRsvpGuest = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msRemoveRsvpGuest,
      monoHandler: monoRemoveRsvpGuest,
      extraData: reqData,
    }
  );
  return data;
};

export const monoRemoveChildFromRsvp = async (_, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_CHILD_FROM_RSVP_LIST,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const removeChildFromRsvp = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msRemoveChildFromRsvp,
      monoHandler: monoRemoveChildFromRsvp,
      extraData: reqData,
    }
  );
  return data;
};

export const monoAddChildToRsvp = async (_, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_CHILD_TO_RSVP_LIST,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const addChildToRsvp = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msAddChildToRsvp,
      monoHandler: monoAddChildToRsvp,
      extraData: reqData,
    }
  );
  return data;
};

const msSaveEventDraft = async (_, reqDatas) => {
  try {
    const { dispatch, reqData } = reqDatas;
    const data = await msSaveNewDraftEvent(dispatch, reqData);
    return data;
  } catch (ex) {
    return ex;
  }
};

export const monoSaveEventDraft = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_OR_SAVE_EVENT_DRAFT,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const saveEventDraft = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msSaveEventDraft,
      monoHandler: monoSaveEventDraft,
      extraData: { reqData, dispatch },
    }
  );
  return data;
};

export const monoUpdateEventDetails = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_EVENT_DETAILS,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateEventDetails = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msUpdateEventDetails,
      monoHandler: monoUpdateEventDetails,
      extraData: { reqData },
    }
  );
  return data;
};

export const monoGetEventPhotoUploadURL = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_EVENT_PHOTO_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getEventPhotoUploadURL = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msGetEventPhotoAlbumUploadUrlForSchool,
      monoHandler: monoGetEventPhotoUploadURL,
      extraData: { reqData },
    }
  );
  return data;
};

export const monoFetchCurrentEventCategory = async (_, reqDatas) => {
  const { reqData, dispatch } = reqDatas;
  const { reqParams, type } = reqData;
  dispatchCurrentEventCategory(dispatch);

  const PAST = 'Past';
  const UPCOMING = 'Upcoming';
  const PENDING = 'Pending';
  const DRAFT = 'Draft';

  const typeActionMap = {
    DRAFT: 'getDraftEvents',
    PENDING: 'getPendingEvents',
    UPCOMING: 'getUpcomingEvents',
    PAST: 'getPastEvents',
  };
  const key = typeActionMap[type.toUpperCase()];

  try {
    let currentQuery = null;

    switch (type) {
      case PAST:
        currentQuery = GET_PAST_EVENTS;
        break;
      case UPCOMING:
        currentQuery = GET_UPCOMING_EVENTS;
        break;
      case PENDING:
        currentQuery = GET_PENDING_EVENTS;
        break;
      case DRAFT:
        currentQuery = GET_DRAFT_EVENTS;
        break;
      default:
        currentQuery = GET_UPCOMING_EVENTS;
        break;
    }
    const data = await SkApolloRequest({
      params: {
        query: currentQuery,
        variables: reqParams,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchCurrentEventCategory(dispatch, false, {
        data: data.data,
        key,
      });
    } else {
      dispatchCurrentEventCategory(
        dispatch,
        false,
        { data: null, key },
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchCurrentEventCategory(
      dispatch,
      false,
      { data: null, key },
      { error: ex.message }
    );
    return ex;
  }
};

export const fetchCurrentEventCategory =
  (reqParams, type) => async dispatch => {
    const reqData = {
      reqParams,
      type,
    };
    dispatchCurrentEventCategory(dispatch);
    const data = await actionHandlerWithFeatureFlag(
      FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
      {
        dispatch,
        msHandler: msFetchCurrentEventCategory,
        monoHandler: monoFetchCurrentEventCategory,
        extraData: { reqData, dispatch, dispatchCurrentEventCategory },
      }
    );
    return data;
  };

export const monoFetchRsvpData = async (dispatch, { reqData }) => {
  dispatchRsvpData(dispatch);
  const filterData = reqData.filter ? reqData.filter : 'attending';
  const resData1 = [
    {
      IDEvent: reqData.IDEvent,
      filter: { status: filterData },
    },
  ];
  const resData2 = [
    {
      IDEvent: reqData.IDEvent,
      RSVPStatus: filterData,
    },
  ];
  const bulkQuery = [
    {
      key: 'getEventRsvpChildList',
      query: EVENT_RSVP_LIST,
      variables: resData1,
    },
    {
      key: 'getRsvpAttendeesClassAndCentreCountByStatus',
      query: EVENT_RSVP_COUNT,
      variables: resData2,
    },
  ];

  try {
    const data = await SkApolloRequest({
      params: bulkQuery,
      type: 'bulkQuery',
    });
    if (data.success) {
      dispatchRsvpData(dispatch, false, data.data);
    } else {
      dispatchRsvpData(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRsvpData(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchRsvpData = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msFetchRsvpData,
      monoHandler: monoFetchRsvpData,
      extraData: { reqData, dispatchRsvpData },
    }
  );
  return data;
};

export const monoFetchEventListing = async (_, reqDatas) => {
  const { reqData, dispatch } = reqDatas;
  const reqParams = [
    {
      IDSchool: get(reqData, 'fkSchool'),
      filter: null,
      pagination: {
        page: DEFAULT_PAGE,
        perPage: DEFAULT_ITEMS_PER_PAGE,
      },
    },
  ];
  const bulkQuery = [
    {
      key: 'getDraftEvents',
      query: EVENT_LISTING_OBJECT,
      variables: reqParams,
    },
    {
      queryKey: '',
      key: 'getPastEvents',
      query: EVENT_LISTING_OBJECT,
      variables: reqParams,
    },
    {
      queryKey: '',
      key: 'getUpcomingEvents',
      query: EVENT_LISTING_OBJECT,
      variables: reqParams,
    },
    {
      queryKey: '',
      key: 'getPendingEvents',
      query: EVENT_LISTING_OBJECT,
      variables: reqParams,
    },
  ];
  try {
    const data = await SkApolloRequest({
      params: bulkQuery,
      type: 'bulkQuery',
    });
    if (data.success) {
      dispatchEvents(dispatch, false, data.data);
    } else {
      dispatchEvents(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchEvents(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchEventListing = reqData => async dispatch => {
  dispatchEvents(dispatch);
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msFetchEventListing,
      monoHandler: monoFetchEventListing,
      extraData: { reqData, dispatch, dispatchEvents },
    }
  );
  return data;
};

export const monoFetchEventByID = async (_, { reqData, dispatch }) => {
  dispatchfetchEventByID(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: EVENT_BY_ID,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchfetchEventByID(dispatch, false, data.data);
    } else {
      dispatchfetchEventByID(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchfetchEventByID(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchEventByID = reqData => async dispatch => {
  dispatchfetchEventByID(dispatch);
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msFetchEventByID,
      monoHandler: monoFetchEventByID,
      extraData: { reqData, dispatch, dispatchfetchEventByID },
    }
  );
  return data;
};

export const monoExportRsvpList = async (_, { reqData, dispatch }) => {
  dispatchExportRsvpList(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: EXPORT_RSVP_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchExportRsvpList(dispatch, false, data.data);
    } else {
      dispatchExportRsvpList(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchExportRsvpList(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const exportRsvpList = reqData => async dispatch => {
  dispatchExportRsvpList(dispatch);
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      dispatch,
      msHandler: msExportRsvpList,
      monoHandler: monoExportRsvpList,
      extraData: { reqData, dispatch, dispatchExportRsvpList },
    }
  );
  return data;
};

export const monoUpdateEventMediaIndex = async (_, { reqData }) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_EVENT_MEDIA_INDEX,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateEventMediaIndex = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
    {
      msHandler: msUpdateEventMediaIndex,
      monoHandler: monoUpdateEventMediaIndex,
      extraData: { reqData },
    }
  );
  return data;
};

export default fetchEventListing;
