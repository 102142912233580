import { filter, get } from 'lodash';
import { FEATURE_FLAGS } from 'utils/constants';
import { classOpsService } from 'utils/msEndpointConstants';
import { actionHandlerWithFeatureFlag, parseGraphQLError } from '../../utils';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS } from '../../utils/msEndpointConstants';
import { FIND_ALL_APPOINTMENTS } from '../models/calenderModel';
import { MutationBuilder } from '../queryBuilder';
import { FETCH_ALL_CALENDAR_DATA, FETCH_APPOINTMENTS } from './actionTypes';
import SkRequest from './BaseRequest';

const {
  EVENT: { GET_CALENDAR_EVENTS },
} = MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS;

const _mMutationEnquiryBuilder = new MutationBuilder();

_mMutationEnquiryBuilder.setNewMutation('addCalendarEvent', {
  ID: 'number',
});

_mMutationEnquiryBuilder.setNewMutation('cancelAvailability', {});

const dispatchCalendarData = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CALENDAR_DATA,
    key: 'allCalendarData',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAppointments = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_APPOINTMENTS,
    key: 'appointments',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchAppointments = reqData => async dispatch => {
  dispatchFetchAppointments(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_APPOINTMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchAppointments(dispatch, false, { data: data.data });
    } else {
      dispatchFetchAppointments(dispatch, false, null, data.error);
    }

    return data.data;
  } catch (ex) {
    dispatchFetchAppointments(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const createAppointment = reqData => async () => {
  const query = _mMutationEnquiryBuilder.getMutationAsString(
    'addCalendarEvent',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelAvailibility = reqData => async () => {
  const query = _mMutationEnquiryBuilder.getMutationAsString(
    'cancelAvailability',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

const monoFetchAllEventsForCalendar = async (_, { reqData, dispatch }) => {
  try {
    const data = await SkApolloRequest({
      params: reqData,
      type: 'bulkQuery',
    });

    if (data.success) {
      dispatchCalendarData(dispatch, false, { data: data.data });
    } else {
      dispatchCalendarData(dispatch, false, null, data.error);
    }

    return data.data;
  } catch (ex) {
    dispatchCalendarData(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

// Integrate Events For Calendar[MS]
const msFetchAllEventsForCalendar = async (
  _,
  { reqData, dispatch, dispatchCalendarData: _dispatchCalendarData }
) => {
  try {
    const _filteredReqData = filter(reqData, query => {
      return get(query, 'key') !== 'getAllEventsForCalendar';
    });

    // Filter the monoFetch to pull all data, except for getAllEventsForCalendar
    const monoData = await monoFetchAllEventsForCalendar(_, {
      reqData: _filteredReqData,
      dispatch,
    });

    const _classOpsService = classOpsService();
    const calendarReqData = get(get(get(reqData, '1'), 'variables'), '0');
    const reqParam = {
      page: get(calendarReqData, 'pageParams.page', 1),
      perPage: get(calendarReqData, 'pageParams.perPage', 20),
      sort: '-updated_at',
      centreIds: get(calendarReqData, 'fkCentre', []),
      from: get(calendarReqData, 'from').split('+').at(0),
      to: get(calendarReqData, 'to').split('+').at(0),
    };

    const response = await _classOpsService.get(
      `${GET_CALENDAR_EVENTS}?${decodeURIComponent(
        new URLSearchParams(reqParam)
      )}`
    );

    const getAllEventsForCalendar = {
      ...response.data,
      __typename: 'ListEvents',
    };

    _dispatchCalendarData(dispatch, false, {
      data: { getAllEventsForCalendar, ...monoData },
    });

    return {
      ...monoData,
      getAllEventsForCalendar,
    };
  } catch (ex) {
    dispatchCalendarData(dispatch, false, null, {
      error: ex,
    });
  }
};

export const fetchAllCalendarData = reqData => async dispatch => {
  dispatchCalendarData(dispatch);
  try {
    const data = await actionHandlerWithFeatureFlag(
      FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
      {
        dispatch,
        msHandler: msFetchAllEventsForCalendar,
        monoHandler: monoFetchAllEventsForCalendar,
        extraData: { reqData, dispatch, dispatchCalendarData },
      }
    );

    return data;
  } catch (ex) {
    dispatchCalendarData(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};
