import React, { useState } from 'react';
import { get, isEmpty, trim } from 'lodash';
import moment from 'moment';
import { Grid, Avatar } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  DateTimePicker,
  TextArea,
} from '../../../common/InputFields';
import Text from '../../../common/Text';
import Button from '../../../common/Button';
import { ErrorHelperText } from '../../../common/HelperComponents';
import { SAVE_DATE_FORMAT, DEFAULT_ERROR } from '../../../../utils/constants';
import { isMomentValid } from '../../../../utils';

const BusChildDetails = ({
  t,
  data,
  cdnUrl,
  isEdit,
  closeModal,
  updateChild,
  assignChild,
}) => {
  const [submitErr, setSubmitErr] = useState('');
  const requiredErrorMsg = t('common.requiredError');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      imageKey: get(data, 'imageKey', null),
      imageKeyPresignedURL: get(data, 'imageKeyPresignedURL', null),
      name: get(data, 'name', ''),
      bc: get(data, 'bc', ''),
      childClass: get(data, 'childClass', ''),
      serviceStartDate: data.serviceStartDate ? data.serviceStartDate : null,
      serviceEndDate: data.serviceEndDate ? data.serviceEndDate : null,
      pickup: get(data, 'pickup', ''),
      remarks: get(data, 'remarks', ''),
    },
    validationSchema: Yup.lazy(values =>
      Yup.object().shape({
        serviceStartDate: Yup.mixed()
          .test('dateValue', t('common.invalidDate'), value => {
            if (!value) {
              return true;
            }
            return value && isMomentValid(moment(value));
          })
          .required(requiredErrorMsg),
        serviceEndDate: Yup.mixed()
          .test('dateValue', t('common.invalidDate'), value => {
            if (!value) {
              return true;
            }
            return value && isMomentValid(moment(value));
          })
          .test('dateValue', t('registration.notAfterEndDate'), value => {
            if (!value) {
              return true;
            }
            return value && moment(values.serviceStartDate).isBefore(value);
          })
          .required(requiredErrorMsg),
        pickup: Yup.string().required(requiredErrorMsg),
        remarks: Yup.string(),
      })
    ),
    onSubmit: async values => {
      const saveData = {
        fkChild: data.childId,
        fkBus: data.fkBus,
        serviceStartDate: moment(values.serviceStartDate).format(
          SAVE_DATE_FORMAT
        ),
        serviceEndDate: moment(values.serviceEndDate).format(SAVE_DATE_FORMAT),
        locationText: values.pickup,
        remarks: values.remarks,
      };

      try {
        let resp = null;

        if (isEdit) {
          resp = await updateChild(data.id, saveData);
        } else {
          resp = await assignChild(saveData);
        }

        if (resp.success) {
          closeModal(true);
        } else {
          setSubmitErr(get(resp, 'error', DEFAULT_ERROR));
        }
      } catch (ex) {
        setSubmitErr(ex.message);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
  } = formik;

  const handleDateChange = e => {
    setFieldTouched([e.target.name], true);
    handleChange(e);
  };

  const disabled = !isEdit && (isEmpty(touched) || !isEmpty(errors));
  const childImageKey = values.imageKey
    ? `${cdnUrl}/${values.imageKey}`
    : `${cdnUrl}/ChildProfile/profile_pic_default.png`;

  return (
    <Grid container spacing={3} className="w3-padding w3-padding-16">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} className="w3-padding-16">
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Avatar
                  alt="child"
                  src={get(values, 'imageKeyPresignedURL') || childImageKey}
                  width="75"
                  style={{ borderRadius: '50%' }}
                >
                  {get(trim(get(data, 'name', '')), '[0]')}
                </Avatar>
              </Grid>
              <Grid item xs={12} md={10}>
                <Text label>{t('registration.childName')}</Text>
                <Text body bold>
                  {values.name}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="w3-padding-16">
            <Text label>{t('common.birthCertificate')}</Text>
            <Text body>{values.bc}</Text>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} className="w3-padding-16 s7t-auto-margin">
            <Text label>{t('common.classLabel')}</Text>
            <Text body>{values.childClass}</Text>
          </Grid>
          <Grid item xs={12} md={4} className="w3-padding-16">
            <DateTimePicker
              name="serviceStartDate"
              label={`${t('common.service')} ${t('common.startDate')}`}
              value={values.serviceStartDate}
              error={get(errors, 'serviceStartDate')}
              handleDateChange={handleDateChange}
              type="date"
              required
            />
          </Grid>
          <Grid item xs={12} md={4} className="w3-padding-16">
            <DateTimePicker
              name="serviceEndDate"
              label={`${t('common.service')} ${t('common.endDate')}`}
              value={values.serviceEndDate}
              error={get(errors, 'serviceEndDate')}
              handleDateChange={handleDateChange}
              type="date"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className="w3-padding-16">
            <TextField
              label={t('bus_management.pickDropLoc')}
              name="pickup"
              value={values.pickup}
              error={get(errors, 'pickup')}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className="w3-padding-16">
            <TextArea
              name="remarks"
              label={t('childprofile.remark')}
              value={values.remarks}
              rows={3}
              error={get(errors, 'remarks')}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              labelStyle={{ paddingBottom: '8px' }}
            />
          </Grid>
        </Grid>
      </Grid>
      {submitErr && (
        <Grid item xs={12} className="w3-center">
          <ErrorHelperText error={submitErr} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3} className="w3-right-align">
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={3}>
            <Button
              size="medium"
              button="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              {t('common.cancelButton')}
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button disabled={disabled} size="medium" onClick={handleSubmit}>
              {t('common.submitButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusChildDetails;
