import { get } from 'lodash';
import moment from 'moment';
import { actionHandlerWithFeatureFlag } from '../../utils';
import apolloUtils from '../../utils/apolloUtils';
import { FETCH_SURVEY_DETAILS_PARENT } from './actionTypes';
import {
  GET_PSS_QUESTIONS,
  GET_SURVEY_FOR_PARENT,
  SAVE_SURVEY_RESPONSE,
  PSS_SURVEY_CONFIG,
  PSS_SURVEY_NOTIFICATION_CONFIG,
} from '../models/pssModel';

import {
  FEATURE_FLAGS,
  SGT_TIME_OFFSET,
  SAVE_DATE_FORMAT,
} from '../../utils/constants';
import {
  childService,
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
} from '../../utils/msEndpointConstants';
import {
  MS_PHASE_2C_PIM_PSS_ENPOINT,
  parentSurveyService,
} from '../../utils/ms-utils/Phase2c';
import {
  modifyParentSurvey,
  modifyConfigResponse,
  transformMsQuestions,
} from '../../utils/ms-utils/Phase2c/HelperPSS';
import DatadogHandler from '../../utils/datadog';

const { GET_CHILD_CLASSES_AT_BY_CHILD_IDS } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CHILD;

const dispatchParentSurveyDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SURVEY_DETAILS_PARENT,
    key: 'pssSurveyDetailsParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const monoFetchPssQuestions = async (_, reqData) => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: GET_PSS_QUESTIONS,
      variables: {
        surveyID: reqData?.surveyID,
        childID: reqData?.childID,
      },
    });
    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

const msFetchPssQuestions = async (_, reqData) => {
  try {
    if (!reqData?.uuid) {
      return;
    }
    const result = await parentSurveyService(reqData?.uuid).post(
      MS_PHASE_2C_PIM_PSS_ENPOINT.GET_SURVEY_QUESTIONS,
      {
        child_id: reqData?.childID,
        survey_id: reqData?.surveyID,
      }
    );
    const data = transformMsQuestions(result?.data);
    return data;
  } catch (error) {
    DatadogHandler.addError(error);
    DatadogHandler.sendLog(
      error,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msFetchPssQuestions',
      },
      'error'
    );
    return { error: error?.message };
  }
};

export const fetchPssQuestions = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: msFetchPssQuestions,
      monoHandler: monoFetchPssQuestions,
      extraData: reqData,
    }
  );
  return data;
};

export const msFetchSurveyDetailsForParent = async (dispatch, reqData) => {
  try {
    const reqParams = {
      parent_id: reqData?.parentID,
      input_source: reqData?.source,
    };
    const pssServiceReqBase = parentSurveyService();
    const result = await pssServiceReqBase.post(
      MS_PHASE_2C_PIM_PSS_ENPOINT.GET_PARENT_SURVEY,
      reqParams
    );

    const childIds = result?.data?.child_surveys?.map(
      survey => survey?.child?.id
    );

    let data = {};
    if (childIds?.length > 0) {
      const currentDateTime = moment()
        .utcOffset(SGT_TIME_OFFSET)
        .format(SAVE_DATE_FORMAT);

      const childServiceReqBase = childService();
      const children = await childServiceReqBase.post(
        ...GET_CHILD_CLASSES_AT_BY_CHILD_IDS(childIds, currentDateTime)
      );

      data = modifyParentSurvey(result, children);
    }
    dispatchParentSurveyDetails(dispatch, false, data);
    return data;
  } catch (ex) {
    dispatchParentSurveyDetails(dispatch, false, null, { error: ex.message });
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msFetchSurveyDetailsForParent',
      },
      'error'
    );
    return { error: ex.message };
  }
};

export const monoFetchSurveyDetailsForParent = async (dispatch, reqData) => {
  dispatchParentSurveyDetails(dispatch);
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: GET_SURVEY_FOR_PARENT,
      variables: reqData,
    });

    dispatchParentSurveyDetails(dispatch, false, result);
    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchParentSurveyDetails(dispatch, false, null, { error: ex.message });
    return { error: errorMessage };
  }
};

export const fetchSurveyDetailsForParent = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: msFetchSurveyDetailsForParent,
      monoHandler: monoFetchSurveyDetailsForParent,
      extraData: reqData,
    }
  );
  return data;
};

const monoSavePssSurveyResponse = async (_, reqParam) => {
  const client = await apolloUtils();
  try {
    const { reqData } = reqParam;
    const result = await client.mutate({
      mutation: SAVE_SURVEY_RESPONSE,
      variables: {
        childID: reqData?.childID,
        parentID: reqData?.parentID,
        surveyID: reqData?.surveyID,
        hashKey: reqData?.hashKey,
        surveyResponse: reqData?.surveyResponse,
        isCompleted: reqData?.isCompleted,
        source: reqData?.source,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

const msSavePssSurveyResponse = async (_, reqParam) => {
  try {
    const { reqData, pssToken } = reqParam;
    if (!pssToken) {
      return;
    }
    const result = await parentSurveyService(pssToken).post(
      MS_PHASE_2C_PIM_PSS_ENPOINT.UPDATE_SURVEY_RESPONSE,
      reqData
    );
    if (get(result, 'data.child.fullname')) {
      return {
        data: {
          updateSurveyResponse: get(result, 'data.child.fullname', ''),
        },
      };
    }
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msSavePssSurveyResponse',
      },
      'error'
    );
    return { error: ex?.message };
  }
};

export const savePssSurveyResponse = (reqData, pssToken) => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: msSavePssSurveyResponse,
      monoHandler: monoSavePssSurveyResponse,
      extraData: { reqData, pssToken },
    }
  );
  return data;
};

const monoGetPssSurveyConfig = async (_, reqData) => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: PSS_SURVEY_CONFIG,
      variables: {
        childID: reqData?.childID,
        surveyID: reqData?.surveyID,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getPssSurveyConfig = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: () => {},
      monoHandler: monoGetPssSurveyConfig,
      extraData: reqData,
    }
  );
  return data;
};

export const msGetPssNotificationSurveyConfig = async () => {
  try {
    const pssServiceReqBase = parentSurveyService();
    const result = await pssServiceReqBase.get(
      MS_PHASE_2C_PIM_PSS_ENPOINT.SURVEY_NOTIFICATION_CONFIG
    );

    const data = modifyConfigResponse(result);

    return data;
  } catch (error) {
    DatadogHandler.addError(error);
    DatadogHandler.sendLog(
      error,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msGetPssNotificationSurveyConfig',
      },
      'error'
    );
    return { error: error.message };
  }
};

export const monoGetPssNotificationSurveyConfig = async () => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: PSS_SURVEY_NOTIFICATION_CONFIG,
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getPssNotificationSurveyConfig = () => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      msHandler: msGetPssNotificationSurveyConfig,
      monoHandler: monoGetPssNotificationSurveyConfig,
    }
  );
  return data;
};

export const msGetSurveyDetailsFromSurveyUuid = pssToken => async () => {
  try {
    if (!pssToken) {
      return;
    }
    const result = await parentSurveyService(pssToken).get(
      MS_PHASE_2C_PIM_PSS_ENPOINT.SURVEY_DETAILS_FROM_SURVEY_UUID
    );
    return result?.data;
  } catch (error) {
    DatadogHandler.addError(error);
    DatadogHandler.sendLog(
      error,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msGetSurveyDetailsFromSurveyUuid',
      },
      'error'
    );
    return { error: error.message };
  }
};

export const msGetSurveyProgress = reqData => async () => {
  try {
    if (!reqData?.pssToken) {
      return;
    }
    const result = await parentSurveyService(reqData?.pssToken).post(
      MS_PHASE_2C_PIM_PSS_ENPOINT.GET_SURVEY_PROGRESS,
      {
        child_id: reqData?.childId,
        survey_id: reqData?.surveyId,
      }
    );
    return result?.data;
  } catch (error) {
    DatadogHandler.addError(error);
    DatadogHandler.sendLog(
      error,
      {
        fileName: 'actions/ParentSatisfactionSurvey.js',
        functionName: 'msGetSurveyProgress',
      },
      'error'
    );
    return { error: error?.message };
  }
};
