import gql from 'graphql-tag';

const ACCOUNT_INFO = `{
    BankAccount {
      ID
      childName
      childBirthCertificate
      child {
        firstname
        lastname
        birthCertificate
      }
      payerAccountNumber
      payerAccountName
      bankBicCode {
        bankName
        ID
      }
      bankAccountStateHistories {
        data {
          updatedAt
        }
      }
      source
      fkBankBicCode
      status
      accountType
      remarks
      billReferenceNumber
      transactionNumber
      source
      active
      isCda
      isSiblingCda
      siblingName
      siblingBirthCertificate
      effectiveFrom
      updatedAt
      createdAt
      documentTypes {
        data {
          ID
          label
          documentTypeConfig {
            ID
          }
          documentTags {
            data {
              document {
                ID
                filename
                uRL
                preSignedUrl
              }
            }
          }
        }
      }
    }
    GiroApplication {
      BankAccountNumber
      IsCDA
      NameOfAccountHolders
      MobilePhoneNumber
      MobilePhoneCountryCode
      ApplicationDate
    }
  }
`;

export const FIND_ACCOUNT_INFO_BY_ENQUIRY_ID = gql`
    query getBankAccountByFkRegistrationChild($regChildId: Int!) {
      getBankAccountByFkRegistrationChild(regChildId: $regChildId)
        ${ACCOUNT_INFO}
    }
`;

export const FIND_ACCOUNT_INFO_BY_CHILD_ID = gql`
    query getBankAccountByFkRegistrationChild($regChildId: Int!) {
      getBankAccountByFkRegistrationChild(regChildId: $regChildId)
        ${ACCOUNT_INFO}
    }
`;

export const GET_ACCOUNT_LISTING = gql`
  query ($isCDA: Boolean!, $paymentType: String!) {
    getRegistrationAccountListing(isCDA: $isCDA, paymentType: $paymentType) {
      AccountName
      AccountNumber
      AccountBankCode
      AccountBranchCode
      AccountType
    }
  }
`;

export const ADD_GIRO = gql`
  mutation ($giroAccount: NewGiroDto!, $paymentType: String!) {
    addRegistrationGiro(giroAccount: $giroAccount, paymentType: $paymentType)
  }
`;

export const MARK_GIRO_AS_COMPLETED = gql`
  mutation ($IDRegistration: Int!) {
    markGiroAsCompleted(IDRegistration: $IDRegistration)
  }
`;

export const GET_PAYNOW_UEN_BY_SCHOOL = gql`
  query ($schoolID: Int!) {
    getPayNowUENBySchool(schoolID: $schoolID) {
      uen
      qrCodeURL
    }
  }
`;

export const EXPORT_GIRO_PDF = gql`
  mutation ($IDBankAccount: Int!, $centreId: Int!) {
    exportGiroApplicationPdf(IDBankAccount: $IDBankAccount, centreId: $centreId)
  }
`;

export const EMAIL_GIRO_PDF = gql`
  mutation (
    $IDBankAccount: Int!
    $centreId: Int!
    $childId: Int
    $IDRegChild: Int
    $IDEcdaRegChild: Int
  ) {
    emailGiroApplicationPdf(
      IDBankAccount: $IDBankAccount
      centreId: $centreId
      childId: $childId
      IDRegChild: $IDRegChild
      IDEcdaRegChild: $IDEcdaRegChild
    )
  }
`;

export const PAY_REGISTRATION_INVOICE_BY_GIRO = gql`
  mutation ($IDRegChild: Int!, $IDInvoice: Int!) {
    payRegistrationInvoiceByGiro(IDRegChild: $IDRegChild, IDInvoice: $IDInvoice)
  }
`;

export const INITIATE_REFUND = gql`
  mutation (
    $IDRegistration: Int!
    $isCDA: Boolean!
    $isNonCDA: Boolean!
    $remarks: String!
  ) {
    initiateRefundForCancelledRegistration(
      IDRegistration: $IDRegistration
      isCDA: $isCDA
      isNonCDA: $isNonCDA
      remarks: $remarks
    )
  }
`;

export const INITIATE_REFUND_ECDA_REGISTRATION = gql`
  mutation (
    $IDRegistration: Int!
    $isCDA: Boolean!
    $isNonCDA: Boolean!
    $remarks: String!
  ) {
    initiateRefundForCancelledEcdaRegistration(
      IDRegistration: $IDRegistration
      isCDA: $isCDA
      isNonCDA: $isNonCDA
      remarks: $remarks
    )
  }
`;

export const GET_PAYMENT_ACCESS = gql`
  query (
    $authCode: String!
    $paymentType: String!
    $sessionToken: String
    $isEcdaRegistration: Boolean
  ) {
    getRegistrationPaymentAccess(
      authCode: $authCode
      paymentType: $paymentType
      sessionToken: $sessionToken
      isEcdaRegistration: $isEcdaRegistration
    )
  }
`;

export const EGIRO_DETAILS_BY_REG_CHILD = gql`
  query getEgiroAccountDetailsByFkRegistrationChild($registrationChildId: Int!) {
    getEgiroAccountDetailsByFkRegistrationChild(registrationChildId: $registrationChildId)
      ${ACCOUNT_INFO}
  }
`;
export const EGIRO_DETAILS_BY_ECDA_REG_CHILD = gql`
  query getEgiroAccountDetailsByFkEcdaRegistrationChild($ecdaRegistrationChildId: Int!) {
    getEgiroAccountDetailsByFkEcdaRegistrationChild(ecdaRegistrationChildId: $ecdaRegistrationChildId)
      ${ACCOUNT_INFO}
  }
`;

export const EGIRO_DETAILS_BY_CHILD = gql`
  query getEgiroBankAccountByChild($childId: Int!) {
    getEgiroBankAccountByChild(childId: $childId)
      ${ACCOUNT_INFO}
  }
`;

export const EDDA_REDIRECTION = gql`
  query getEddaRedirection(
    $childID: Int
    $registrationChildID: Int
    $userID: Int!
    $centreId: Int!
    $billingRefNumber: String
    $applicantBankCode: String!
  ) {
    getEddaRedirection(
      childID: $childID
      registrationChildID: $registrationChildID
      userID: $userID
      centreId: $centreId
      billingRefNumber: $billingRefNumber
      applicantBankCode: $applicantBankCode
    )
  }
`;

export const ECDA_EDDA_REDIRECTION = gql`
  query getEddaRedirectionForEcda(
    $ecdaRegistrationChildID: Int!
    $userID: Int!
    $centreId: Int!
    $billingRefNumber: String
    $applicantBankCode: String!
  ) {
    getEddaRedirectionForEcda(
      ecdaRegistrationChildID: $ecdaRegistrationChildID
      userID: $userID
      centreId: $centreId
      billingRefNumber: $billingRefNumber
      applicantBankCode: $applicantBankCode
    )
  }
`;

export const UPDATE_TRANSACTION_STATUS = gql`
  mutation updateTransactionStatus(
    $transactionNumber: String!
    $status: String!
    $childId: Int
    $registrationChildId: Int
  ) {
    updateTransactionStatus(
      transactionNumber: $transactionNumber
      status: $status
      childId: $childId
      registrationChildId: $registrationChildId
    )
  }
`;

export const ADD_BANK_ACCOUNT_FOR_REFUND = gql`
  mutation (
    $IDChild: Int
    $IDRegChild: Int
    $IDECDARegChild: Int
    $IDSchool: Int!
    $application: GiroApplication!
    $isSiblingCda: Boolean
  ) {
    addBankAccountForRefund(
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDECDARegChild: $IDECDARegChild
      IDSchool: $IDSchool
      application: $application
      isSiblingCda: $isSiblingCda
    ) {
      ID
      documentTypes {
        data {
          ID
          documentTypeConfig {
            ID
            label
            code
          }
        }
      }
    }
  }
`;

export const FIND_ECDA_ACCOUNT_INFO_BY_ID = gql`
    query getBankAccountByFkEcdaRegistrationChild($IDEcdaRegChild: Int!) {
      getBankAccountByFkEcdaRegistrationChild(IDEcdaRegChild: $IDEcdaRegChild)
        ${ACCOUNT_INFO}
    }
`;

export const GET_ECDA_REGISTRATION_INVOICE = gql`
  query GET_ECDA_REGISTRATION_INVOICE($IDRegistration: Int!) {
    getEcdaRegistrationInvoice(IDRegistration: $IDRegistration) {
      ID
      invoiceNo
      invoiceType
      invoiceItems {
        totalCount
        data {
          ID
          forMonth
          fkInvoice
          quantity
          label
          displayAdjustedAmount
          unitPrice
          taxRate
          taxAmount
          totalAmount
          billableItem {
            sn1StrAccPacAccountCode
            label
            unitPrice
            taxable
            type
            cdaDeductible
            displayOrder
          }
        }
      }
      grandTotal
      pdfUrl
      preSignedUrl
      totalAmount
      totalTaxAmount
      invoiceDate
      invoiceDueDate
      invoiceVoidNotes {
        totalCount
        data {
          ID
          voidNoteNo
          voidRemark
          voidAt
          statusBeforeVoid
        }
      }
      totalDiscountAmount
      outstandingAmount
      receipts {
        data {
          ID
          receiptNo
          amount
          amountReceived
          advancePaymentAmount
          creditAmountUsed
        }
        totalCount
      }
      status
      updatedAt
    }
  }
`;
