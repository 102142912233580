import gql from 'graphql-tag';

import {
  APPLICANT_ENROLMENT_FRAGMENT,
  CHILD_HEALTH_INFOMATION_FRAGMENT,
  ECDA_EMERGENCY_CONTACT_FRAGMENT,
  ECDA_REGISTRATION_CHILD_FRAGMENT,
  ECDA_REGISTRATION_FRAGMENT,
  FAMILY_DOCTOR_FRAGMENT,
  MEDICAL_CONDITION_DTO_FRAGMENT,
  SPOUSE_ENROLMENT_FRAGMENT,
  ECDA_REGISTRATION_PARENT_FRAGMENT,
} from './commonFragments';

export const START_REGISTRATION = gql`
  mutation startRegistration($IDRegistration: Int!) {
    startRegistration(IDRegistration: $IDRegistration)
  }
`;

export const START_ECDA_REGISTRATION = gql`
  mutation startECDARegistration($IDRegistration: Int!) {
    startECDARegistration(IDECDARegistration: $IDRegistration)
  }
`;

export const GET_CONFIRMATION_FOR_SPOUSE = gql`
  query requireConfirmationForSpouse(
    $IDRegChild: Int!
    $identificationNo: String!
    $email: String!
  ) {
    requireConfirmationForSpouse(
      IDRegChild: $IDRegChild
      identificationNo: $identificationNo
      email: $email
    )
  }
`;

export const GET_REGISTRATION_BY_ID_ECDAMVP = gql`
  query ecdaRegistrationByID(
    $IDRegistration: Int!
    $filter: EcdaRegistrationFilter
  ) {
    ecdaRegistrationByID(IDRegistration: $IDRegistration, filter: $filter) {
      ID
      fkUser
      registrationStartDate
      registrationDeadline
      currentFrontendStatus
      previousFrontendStatus
      state
      documentStatus
      createdAt
      updatedAt
      active
      paymentStatus
      giroStatus
      isApplicationSubmitted
      documentNotificationSent
      cancelledBy
      cancelledAt
      fkCancelCode
      remarks
      type
      registrationStartDate
      enrolmentDate
      enquiryDate
      tcAcknowledgeBy
      tcDocumentURL
      tcDocumentDownloadURL
      marketingConsentBy
      centre {
        ID
        label
        code
        payNowEmail
        addresses {
          totalCount
          data {
            ID
            name
            address
            postcode
            fkCentre
            lat
            lng
            building
            floor
            unit
            city
          }
        }
      }
      program {
        ID
        label
      }
      level {
        ID
        label
      }

      staff {
        ID
        userByFkUser {
          firstname
          lastname
        }
      }
      ecdaRegistrationParent {
        fullname
        mobilePhone
        mobilePhoneCountryCode
        email
        workplaceStaff
        ecdaRegistrationChildParents {
          data {
            fkRelationship
          }
        }
      }
      ecdaRegistrationChild {
        fullname
        ID
        fkChild
        race
        nationality
        gender
        dateOfBirth
        imageKey
        birthCertificate
        numFamilyMembers
        householdIncomeRange
        siblings {
          data {
            ID
            firstname
            lastname
          }
        }
      }
      lead {
        hubspotEnquiryID
      }
      userByTcAcknowledgeBy {
        isParent
        ecdaRegistrationParentsByFkUser {
          totalCount
        }
      }
      feeTier {
        ID
        label
      }
    }
  }
`;

export const GET_REGISTRATION_BY_ID = gql`
  query registrationByID($IDRegistration: Int!) {
    registrationByID(IDRegistration: $IDRegistration) {
      ID
      fkUser
      registrationStartDate
      registrationDeadline
      state
      consentStatus
      documentStatus
      createdAt
      updatedAt
      active
      paymentStatus
      giroStatus
      showNOC
      isApplicationSubmitted
      currentFrontendStatus
      documentNotificationSent
      cancelledBy
      cancelledAt
      previousFrontendStatus
      remarks
      type
      code {
        description
      }
      staff {
        ID
        userByFkUser {
          firstname
          lastname
        }
      }
      forms {
        data {
          ID
          eDocumentStatus
          fkEnquiry
          formParticipants {
            data {
              ID
              fkForm
              parent {
                firstname
                lastname
                mobilePhone
              }
              user {
                firstname
                lastname
              }
              displayName
              order
              email
              status
              updatedAt
            }
          }
        }
      }
      registrationChildMappings {
        data {
          ID
          fkLevel
          fkCentre
          fkProgram
          enrolmentDate
          enquiryDate
          registrationChild {
            ID
            fkChild
            firstname
            lastname
            race
            nationality
            dateOfBirth
            gender
            birthCertificate
            householdIncomeRange
            mainApplicant {
              firstname
              lastname
              email
              mobilePhoneCountryCode
              mobilePhone
              identificationNo
              workplaceStaff
              workingStatus
              reasonOfUnemployment
              expectedDeliveryDate
            }
            siblings {
              data {
                ID
                firstname
                lastname
              }
            }
            registrationChildParents(filter: { type: "applicant" }) {
              data {
                ID
                fkRelationship
                registrationParent {
                  identificationNo
                  workplaceStaff
                  firstname
                  lastname
                  ID
                  mobilePhone
                  mobilePhoneCountryCode
                  email
                  addresses {
                    totalCount
                    data {
                      fkUser
                      address
                      building
                      floor
                      unit
                      city
                      country
                      postcode
                    }
                  }
                }
              }
            }
          }
          centre {
            ID
            label
            code
            payNowEmail
            addresses {
              totalCount
              data {
                ID
                name
                address
                postcode
                fkCentre
                lat
                lng
                building
                floor
                unit
                city
              }
            }
          }
          program {
            ID
            label
          }
          level {
            ID
            label
          }
        }
      }
      feeTier {
        ID
        label
      }
    }
  }
`;

export const GET_REGISTRATION_STATUS_BY_ID = gql`
  query registrationByID($IDRegistration: Int!) {
    registrationByID(IDRegistration: $IDRegistration) {
      ID
      state
      currentFrontendStatus
    }
  }
`;

export const GET_REG_ENROLMENT_FORM = gql`
  query getRegEnrolmentForm($registrationID: Int!) {
    getRegEnrolmentForm(IDRegistration: $registrationID) {
      allowedSteps
      registration {
        ID
        state
        documentStatus
        consentStatus
        registrationStartDate
        sugAcknowledgeBy
        documentNotificationSent
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
      }
      child {
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
        ID
        firstname
        lastname
        dateOfBirth
        nationality
        race
        gender
        birthCertificate
        householdIncomeRange
        applyForSug
        typeOfReferral
        referralChannel
        otherReferralChannel
        applyFinancialAssistance
        isBigHousehold
        numFamilyMembers
        numDependents
        coPaymentAmount
        hasEmergencyContact
        supportScheme
        financialStartDate
        financialEndDate
      }
      mainApplicant {
        ...applicantFragment
      }
      spouse {
        ...spouseFragment
      }
      familyMembers {
        totalCount
        data {
          firstname
          lastname
          email
          identificationNo
          workingStatus
          occupationalTitle
          dateOfBirth
          hasCpf
          hasNoa
          isRecentlyEmployed
          commencementDate
          salariedMonthlyIncome
          selfEmployedMonthlyIncome
          fkRelationship
        }
      }
    }
  }
  ${APPLICANT_ENROLMENT_FRAGMENT}
  ${SPOUSE_ENROLMENT_FRAGMENT}
`;

export const SAVE_REG_ENROLMENT_FORM_GQL = gql`
  mutation saveRegEnrolmentForm(
    $registrationID: Int!
    $step: Int!
    $regForm: RegistrationEnrolmentForm!
  ) {
    saveRegEnrolmentForm(
      IDRegistration: $registrationID
      step: $step
      regForm: $regForm
    ) {
      allowedSteps
      registration {
        ID
        sugAcknowledgeBy
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
      }
      child {
        ID
        firstname
        lastname
        dateOfBirth
        nationality
        race
        gender
        birthCertificate
        householdIncomeRange
        applyForSug
        typeOfReferral
        referralChannel
        otherReferralChannel
        applyFinancialAssistance
        isBigHousehold
        numFamilyMembers
        numDependents
        coPaymentAmount
        supportScheme
      }
      mainApplicant {
        ...applicantFragment
      }
      spouse {
        ...spouseFragment
      }
      familyMembers {
        totalCount
        data {
          firstname
          lastname
          email
          identificationNo
          workingStatus
          occupationalTitle
          dateOfBirth
          hasCpf
          hasNoa
          isRecentlyEmployed
          commencementDate
          salariedMonthlyIncome
          selfEmployedMonthlyIncome
          fkRelationship
        }
      }
    }
  }
  ${APPLICANT_ENROLMENT_FRAGMENT}
  ${SPOUSE_ENROLMENT_FRAGMENT}
`;

export const GET_ECDA_REG_ENROLMENT_FORM = gql`
  query getECDARegEnrolmentForm($registrationID: Int!) {
    getECDARegEnrolmentForm(IDRegistration: $registrationID) {
      allowedSteps
      isParentConsented
      marketingChannels {
        IsEmail
        IsSMS
        IsPhone
      }
      ecdaRegistration {
        ...EcdaRegistrationFragment
      }
      child {
        ...EcdaRegistrationChildFragment
      }
      mainApplicant {
        ...ECDARegistrationParentFragment
      }
      spouse {
        ...ECDARegistrationParentFragment
      }
      emergencyContactInfo {
        ...EmergencyContactFragment
      }
      healthInfo {
        ...ChildHealthInformationFragment
      }
    }
  }
  ${ECDA_REGISTRATION_FRAGMENT}
  ${ECDA_REGISTRATION_CHILD_FRAGMENT}
  ${ECDA_EMERGENCY_CONTACT_FRAGMENT}
  ${CHILD_HEALTH_INFOMATION_FRAGMENT}
  ${FAMILY_DOCTOR_FRAGMENT}
  ${MEDICAL_CONDITION_DTO_FRAGMENT}
  ${ECDA_REGISTRATION_PARENT_FRAGMENT}
`;

export const SAVE_ECDA_REG_ENROLMENT_FORM_GQL = gql`
  mutation saveECDARegEnrolmentForm(
    $registrationID: Int!
    $step: Int!
    $regForm: ECDARegistrationEnrolmentForm!
  ) {
    saveECDARegEnrolmentForm(
      IDECDARegistration: $registrationID
      step: $step
      regForm: $regForm
    ) {
      allowedSteps
      isParentConsented
      marketingChannels {
        IsEmail
        IsSMS
        IsPhone
      }
      ecdaRegistration {
        ...EcdaRegistrationFragment
      }
      child {
        ...EcdaRegistrationChildFragment
      }
      emergencyContactInfo {
        ...EmergencyContactFragment
      }
      mainApplicant {
        ...ECDARegistrationParentFragment
      }
      spouse {
        ...ECDARegistrationParentFragment
      }
      healthInfo {
        ...ChildHealthInformationFragment
      }
    }
  }
  ${ECDA_REGISTRATION_FRAGMENT}
  ${ECDA_REGISTRATION_CHILD_FRAGMENT}
  ${ECDA_EMERGENCY_CONTACT_FRAGMENT}
  ${ECDA_REGISTRATION_PARENT_FRAGMENT}
  ${CHILD_HEALTH_INFOMATION_FRAGMENT}
  ${FAMILY_DOCTOR_FRAGMENT}
  ${MEDICAL_CONDITION_DTO_FRAGMENT}
`;

export const REQUIRE_CONFIRMATION_FOR_ECDA_SPOUSE = gql`
  query requireConfirmationForECDASpouse(
    $IDECDARegChild: Int!
    $identificationNo: String!
    $email: String!
  ) {
    requireConfirmationForECDASpouse(
      IDECDARegChild: $IDECDARegChild
      identificationNo: $identificationNo
      email: $email
    )
  }
`;

export const GET_CANCELLATION_REASONS = gql`
  query GetCancellationReasons($schoolID: Int!) {
    getCancellationReasonList(schoolID: $schoolID) {
      Id
      label
      description
      reasons {
        key
        subReasons {
          Id
          label
          description
        }
      }
    }
  }
`;

export const GET_REGISTRATION_CANCELLATION_DETAIL = gql`
  query GetRegistrationCancellationDetail($IDEcdaRegChild: Int, $IDChild: Int) {
    getRegistrationCancellationDetail(
      IDEcdaRegChild: $IDEcdaRegChild
      IDChild: $IDChild
    ) {
      isCancelled
      reason {
        description
      }
      subReason {
        description
      }
      operatorDetail {
        name
        remarks
      }
      remarks
      cancelledBy
      cancelledAt
    }
  }
`;

export const CANCEL_ECDA_REGISTRATION = gql`
  mutation cancelECDARegistration(
    $registrationID: Int!
    $isReapplication: Boolean!
    $remarks: String
    $cancellationReason: Int!
    $bypassExceeding: Boolean
    $cancellationSubreasonList: [CancelSubreason!]
  ) {
    cancelECDARegistration(
      IDECDARegistration: $registrationID
      isReapplication: $isReapplication
      remarks: $remarks
      cancellationSubreasonList: $cancellationSubreasonList
      IDCancellationReason: $cancellationReason
      BypassExceeding: $bypassExceeding
    ) {
      ID
    }
  }
`;
